import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withRoot from '../withRoot';

import ResourceCategories from '../constants/ResourceCategories';
import Layout from '../components/Layout';
import container from '../theme/styles/container';
import section from '../theme/styles/section';
import resource from '../theme/styles/resource';
import { theme, transformTransition } from '../theme/theme';

const styles = () => ({
  ...container,
  ...section,
  ...resource,
  footerChevron: {
    display: 'block',
    width: '104%',
    maxWidth: '104%',
    position: 'absolute',
    left: '-2%',
    right: '-2%',
    marginTop: -2,
    zIndex: 5,
  },
  footerChevronContainer: {
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    paddingBottom: '20%',
  },
  resourceImageContainer: {
    width: '100%',
    height: 500,
    overflow: 'hidden',
    position: 'relative',
    marginBottom: -(theme.spacing.unit * 4),
  },
  resourceCategory: {
    borderColor: theme.palette.background.default,
    right: theme.spacing.unit * 5,
  },
  resourceSection: {
    paddingTop: 80,
  },
  resourceBody: {
    '& > p': {
      lineHeight: '1.4',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.125rem',
      },
    },
    '& a': {
      color: theme.palette.primary.main,
      transition: 'color 200ms ease',
      position: 'relative',

      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: -2,
        left: 0,
        right: 0,
        margin: 'auto',
        width: '100%',
        height: 2,
        transform: 'scaleX(0)',
        background: theme.palette.common.black,
        transition: transformTransition,
      },

      '&:hover, &:focus': {
        color: theme.palette.common.black,
        textDecoration: 'none',
        outline: 'none',

        '&:after': {
          transform: 'scaleX(1)',
        },
      },
    },
  },
});

const ResourceArticle = ({ data, classes }) => {
  const resourceCategory = category =>
    ResourceCategories.find(x => x.key === category) || ResourceCategories[0];

  return (
    <Layout title={data.markdownRemark.frontmatter.title}>
      <div className={classes.resourceImageContainer}>
        <Img
          fluid={data.markdownRemark.frontmatter.banner.childImageSharp.fluid}
          className={classes.resourceImage}
          title="Banner Image"
          alt={data.markdownRemark.frontmatter.title}
        />
        <div className={classes.resourceImageMaskContainer}>
          <img
            src={withPrefix('/img/img-mask--alt.svg')}
            alt=""
            className={classes.resourceImageMask}
          />
          <div
            className={classNames(
              classes.category,
              classes.resourceCategory,
              classes[data.markdownRemark.frontmatter.category],
            )}
          >
            <img
              src={withPrefix(
                resourceCategory(data.markdownRemark.frontmatter.category).url,
              )}
              alt={data.markdownRemark.frontmatter.category}
              className={classes.resourceCategoryIcon}
            />
          </div>
        </div>
      </div>

      <div
        className={classNames(
          classes.containerSmall,
          classes.section,
          classes.resourceSection,
        )}
      >
        <Typography component="subtitle2" variant="subtitle2">
          {data.markdownRemark.frontmatter.date}
        </Typography>
        <Typography component="h1" variant="h1">
          {data.markdownRemark.frontmatter.title}
        </Typography>
        <div
          className={classes.resourceBody}
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </div>

      <div className={classes.footerChevronContainer}>
        <img
          src={withPrefix('/img/bottom--alt.svg')}
          alt=""
          className={classes.footerChevron}
        />
      </div>
    </Layout>
  );
};

export default withRoot(withStyles(styles)(ResourceArticle));

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        category
        date(formatString: "MMMM DD, YYYY")
        banner {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
